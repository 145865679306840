import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectFilterLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectTestudioFinanciero', method: 'testudioFinanciero.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectEstudioFinanciero (Vue, filter, search, sorter, page, idcliente, idclientePotencial) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
    if (idclientePotencial) {
      apiFilter.addExact('idcliente_potencial', idclientePotencial)
    } else {
      apiFilter.addExact('idcliente', idcliente)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.estudioFinanciero.searchFilter(search))
    }
    if (filter.resultado.value) {
      apiFilter.addExact(filter.resultado.field, filter.resultado.value)
    }
    if (filter.testudioFinanciero.value) {
      apiFilter.addExact(filter.testudioFinanciero.field, filter.testudioFinanciero.value)
    }
    const resp = await Vue.$api.call(
      'estudioFinanciero.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectEstudioFinancieroRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idestudio_financiero', pks)
    const resp = await Vue.$api.call('estudioFinanciero.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteEstudioFinanciero (Vue, idestudioFinanciero) {
    await Vue.$api.call('estudioFinanciero.delete', { idestudio_financiero: idestudioFinanciero })
  },
}
